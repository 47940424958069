define("ember-link/index", ["exports", "ember-link/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Link", {
    enumerable: true,
    get: function () {
      return _link.default;
    }
  });
  Object.defineProperty(_exports, "UILink", {
    enumerable: true,
    get: function () {
      return _link.UILink;
    }
  });
});